.playerTable tbody {
  display: block;
  max-height: 600px;
  overflow-y: scroll;
}

.playerTable thead, .playerTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.playerTable thead {
  width: calc( 100% - 1.1em );
}

.playerTable tr th:nth-child(1){
   width: 25%;
 }

 .playerTable tr td:nth-child(1){
   width: 25%;
}


.playerTableMobile tbody {
  display: block;
  max-height: 600px;
  overflow-y: scroll;
}

.playerTableMobile thead, .playerTableMobile tbody tr {
  display: table;
  width: calc( 100% - 1.1em );
  table-layout: fixed;
}

.playerTableMobile thead {
  width: calc( 100% - 1.1em );
  margin-right: 0;
}

.playerTableMobile tr th:nth-child(1){
   width: 50%;
 }

 .playerTableMobile tr td:nth-child(1){
   width: 50%;
}

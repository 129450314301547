.footer {
  margin-top: 4rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 1px solid #c8d4d6;
	background-color: white;
}

.footer p,
.footer h6,
.footer ul,
.footer a {
	color: #999;
}

.row {
  margin-bottom: 0px;
}

.footer a {
	text-decoration: none;
}

.footer .footer-col {
	display: inline-block;
	width: 14.5rem;
    margin-right: 1rem;
	margin-bottom: 0.5rem;
    vertical-align: top;
}

.footer h5 {
	margin-bottom: 0.625rem;
}

.footer .li-space-lg li {
	margin-bottom: 0.375rem;
}

.footer .list-unstyled .fab,
.footer .list-unstyled .fas {
	width: 0.875rem;
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.footer .list-unstyled .media-body {
	margin-left: 0.625rem;
}

.footer .fa-stack {
	width: 2em;
	margin-bottom: 0.75rem;
	margin-right: 0.375rem;
	font-size: 1.25rem;
}

.footer .fa-stack .fa-stack-1x {
    color: #fff;
	transition: all 0.2s ease;
}

.footer .fa-stack .fa-stack-2x {
	color: #999;
	transition: all 0.2s ease;
}

.footer .fa-stack:hover .fa-stack-1x {
	color: #fff;
}

.footer .fa-stack:hover .fa-stack-2x {
    color: #555;
}

.copyright {
  padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	background-color: #0E1C36;
	text-align: center;
}

.copyright p,
.copyright a {
	color: white;
}

.copyright a {
	text-decoration: none;
}

.waiversTable tbody {
  display: block;
  max-height: 400px;
  overflow-y: scroll;
}

.waiversTable thead, .waiversTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.waiversTable thead {
  width: calc( 100% - 1.1em );
}

.waiversTable tr th:nth-child(1){
   width: 25%;
 }

 .waiversTable tr td:nth-child(1){
   width: 25%;
}


.waiversTableMobile tbody {
  display: block;
  max-height: 400px;
  overflow-y: scroll;
}

.waiversTableMobile thead, .waiversTableMobile tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.waiversTableMobile thead {
  width: calc( 100% - 1.1em );
}

.waiversTableMobile tr th:nth-child(1){
   width: 50%;
 }

 .waiversTableMobile tr td:nth-child(1){
   width: 50%;
}
